<template>
  <div style="margin-top: 31px;">
    <!-- For title -->
    <div class="columns is-multiline">
      <div class="column is-10 content-title">
        <h1 class="content-title__text">Thank You Page</h1>
      </div>
      <div class="column is-2"></div>
    </div>

    <!-- For detail -->
    <div class="columns is-multiline separator-line">
      <!-- For page title -->
      <div class="column is-3">
        <b>Title</b>
      </div>
      <div class="column is-9">
        <template v-if="$route.name != 'showAssessment'">
          <ValidationProvider
            :vid="`assessmentThankYouPageTitle`"
            name="Assessment Thank You Page Title"
            rules="required"
            v-slot="{ errors }"
          >
            <b-field :type="errors.length > 0 ? 'is-danger' : null">
              <b-input v-model="data.title"></b-input>
            </b-field>
            <span class="required">{{ errors[0] }}</span>
          </ValidationProvider>
        </template>
        <template v-else>
          {{ data.title }}
        </template>
      </div>

      <!-- For page description -->
      <div class="column is-3">
        <b>Description</b>
      </div>
      <div class="column is-9">
        <template v-if="$route.name != 'showAssessment'">
          <ValidationProvider
            :vid="`assessmentThankYouPageDescription`"
            name="Assessment Thank You Page Description"
            rules="required"
            v-slot="{ errors }"
          >
            <b-field :type="errors.length > 0 ? 'is-danger' : null">
              <ckeditor v-model="data.description" :config="editorConfig" />
            </b-field>
            <span class="required">{{ errors[0] }}</span>
          </ValidationProvider>
        </template>
        <template v-else>
          <div v-html="data.description"></div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'assessment-information',
  props: {
    data: {
      default: () => {},
    },
  },
  data() {
    return {
      editorConfig: {
        // The configuration of the editor.
        extraAllowedContent: 'div[class]',
        allowedContent: true,
        // filebrowserUploadMethod: "form",
        // filebrowserUploadUrl: process.env.VUE_APP_BACKEND_URL + "/api/ckeditor/upload-image",
        extraPlugins: 'justify,font',
      },

      isFetchingCategory: false,
      categoryList: [],
    }
  },
}
</script>
