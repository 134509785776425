<template>
  <section class="main" v-if="!isLoading" ref="element">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(savePopup)">
        <div>
          <div class="columns is-multiline" v-if="!isLoading">
            <div class="column is-12">
              <h1 class="is-size-3"><b>Assessment Setup</b></h1>
            </div>
            <div class="column bar">
              <nav class="breadcrumb" aria-label="breadcrumbs">
                <ul>
                  <li>
                    <h3 class="is-size-6">Assessment</h3>
                  </li>
                  <li>
                    <router-link :to="{ name: `assessment` }">
                      <h3 class="is-size-6">Assessment Setup</h3>
                    </router-link>
                  </li>
                  <li>
                    <h3
                      class="is-size-6 breadcrumb-item"
                      v-if="$route.name == 'createAssessment'"
                    >
                      Create Assessment
                    </h3>
                    <h3 class="is-size-6 breadcrumb-item" v-else>
                      Edit Assessment
                    </h3>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="column bar">
              <b-field grouped position="is-right">
                <b-button class="is-hcc" native-type="submit">Save</b-button>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <div class="box">
                <AssessmentInformation :data="information" />

                <AssessmentSurvilance :data="survilance" />

                <AssessmentThankYouPage :data="thankYouPage" />

                <AssessmentPeriod :data="period" />
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import AssessmentInformation from '@/components/V2/Assessment/Detail/AssessmentInformation'
import AssessmentSurvilance from '@/components/V2/Assessment/Detail/AssessmentSurvilance'
import AssessmentPeriod from '@/components/V2/Assessment/Detail/AssessmentPeriod'
import AssessmentThankYouPage from '@/components/V2/Assessment/Detail/AssessmentThankYouPage'
import { mapActions } from 'vuex'

export default {
  components: {
    AssessmentInformation,
    AssessmentSurvilance,
    AssessmentPeriod,
    AssessmentThankYouPage,
  },
  name: 'form-assessment',
  data() {
    return {
      // For Loading Page
      isLoading: false,

      // For Information Data
      information: {
        title: '',
        description: '',
      },

      // For survilance
      survilance: {
        screenCaptureTime: '',
        screenShotTime: '',
      },

      // For period
      period: {
        isUsePeriod: true,
        startPeriod: '',
        endPeriod: '',
      },

      // For Thank You Page Data
      thankYouPage: {
        title: '',
        description: '',
      },

      isEditing: false,
    }
  },
  async created() {
    if (this.$route.name == 'editAssessment') {
      const response = await this.fetchAssessment(
        this.$route.params.newAssessmentId
      )

      this.information = response.data.information
      this.survilance = response.data.survilance
      this.thankYouPage = response.data.thankYouPage
      this.period = response.data.period
      this.period.startPeriod =
        this.period.startPeriod == null ? '' : new Date(this.period.startPeriod)
      this.period.endPeriod =
        this.period.endPeriod == null ? '' : new Date(this.period.endPeriod)
    }

    this.isLoading = false
  },
  methods: {
    ...mapActions({
      fetchAssessment: 'newAssessment/fetchAssessment',
      createAssessment: 'newAssessment/createAssessment',
      updateAssessment: 'newAssessment/updateAssessment',
    }),

    // For save alert
    savePopup() {
      this.$buefy.dialog.confirm({
        title: 'Save Assessment',
        message: `Are you sure want to save this assessment?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, save it!',
        type: 'is-hcc',
        onConfirm: () => this.saveAssessment(),
      })
    },

    // For save assessment
    async saveAssessment() {
      let data = {
        // For id
        id: this.$route.params.newAssessmentId,

        // For information data
        title: this.information.title,
        description: this.information.description,

        // For survilance
        screenShotTime: this.survilance.screenShotTime,
        screenCaptureTime: this.survilance.screenCaptureTime,

        // For thank you page
        thankYouPageTitle: this.thankYouPage.title,
        thankYouPageDescription: this.thankYouPage.description,

        // For period
        isUsePeriod: this.period.isUsePeriod,
        startPeriod: this.period.startPeriod,
        endPeriod: this.period.endPeriod,
      }

      let error = false

      try {
        if (this.$route.name == 'createAssessment') {
          await this.createAssessment(data)
          this.success('Create Assessment Success')
        } else {
          const response = await this.updateAssessment(data)
          if (response.data.status == 500) {
            this.danger('Assessment already in use')
          } else {
            this.success('Update Assessment Success')
          }
        }
      } catch (err) {
        error = true
        this.danger(err.response.data.message)
      }

      if (!error) {
        this.isEditing = true

        this.$router.push('/assessment')
      }
    },

    // For succes alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },

    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing == false) {
      const answer = window.confirm(
        'Do you really want to leave? you have unsaved changes!'
      )
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
}
</script>
<style>
.prev {
  margin-left: 1.1% !important;
}
.button-next {
  margin-right: 1% !important;
}
</style>
