<template>
  <div style="margin-top: 31px;">
    <!-- For title -->
    <div class="columns is-multiline">
      <div class="column is-11 content-title">
        <h1 class="content-title__text">
          Period Time
        </h1>
      </div>
      <div class="column is-1" style="padding-bottom: 0; margin-top: 10px">
        <template v-if="$route.name != 'showAssessment'">
          <b-field
            grouped
            position="is-right"
            style="padding-bottom: 0; padding-right: 0;"
          >
            <b-switch v-model="data.isUsePeriod" type="is-hcc"></b-switch>
          </b-field>
        </template>
      </div>
    </div>

    <!-- For detail -->
    <div class="columns is-multiline separator-line" v-if="data.isUsePeriod">
      <!-- For start period -->
      <div class="column is-3" style="height: 45px;">
        <b>Start Period</b>
      </div>
      <div class="column is-9">
        <template v-if="$route.name != 'showAssessment'">
          <ValidationProvider
            :vid="`startDate`"
            name="Start Date"
            rules="required"
            v-slot="{ errors }"
          >
            <b-field :type="errors.length > 0 ? 'is-danger' : null">
              <b-datetimepicker
                type="is-hcc"
                placeholder="Select Date & Time"
                v-model="data.startPeriod"
                icon="calendar"
                :max-datetime="data.endPeriod"
              ></b-datetimepicker>
            </b-field>
            <span class="required">{{ errors[0] }}</span>
          </ValidationProvider>
        </template>
        <template v-else>
          {{ data.startPeriod }}
        </template>
      </div>

      <!-- For end period -->
      <div class="column is-3" style="height: 45px;">
        <b>End Period</b>
      </div>
      <div class="column is-9">
        <template v-if="$route.name != 'showAssessment'">
          <ValidationProvider
            :vid="`endDate`"
            name="End Date"
            rules="required"
            v-slot="{ errors }"
          >
            <b-field :type="errors.length > 0 ? 'is-danger' : null">
              <b-datetimepicker
                type="is-hcc"
                placeholder="Select Date & Time"
                v-model="data.endPeriod"
                icon="calendar"
                :min-datetime="data.startPeriod"
              ></b-datetimepicker>
            </b-field>
            <span class="required">{{ errors[0] }}</span>
          </ValidationProvider>
        </template>
        <template v-else>
          {{ data.endPeriod }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'assessment-setting',
  props: {
    data: {
      default: () => {},
    },
  },
}
</script>
